const addAttributeOnScroll = (selector, attribute, value) => selector.setAttribute(attribute, value)
const addClassOnScroll = (selector, classNames) => selector.classList.add(classNames)
const removeAttributeOnScroll = (selector, attribute) => selector.removeAttribute(attribute)
const removeClassOnScroll = (selector, classNames) => selector.classList.remove(classNames)

const initialNavbarPosition = 545

document.addEventListener('turbolinks:load', () => {
  const navbar = document.querySelector('nav')
  const navbarBrand = document.querySelector('.navbar-brand')
  const navbarMenu = document.querySelector('#navBarMenu')

  navbarMenu.addEventListener('click', (event) => {
    if (event.target.tagName === 'A') {
      const href = event.target.getAttribute('href')
      if (href) {
        window.location.href = href
      }
    }
  })

  window.addEventListener('scroll', () => {
    const scrollPosition = window.scrollY

    if (scrollPosition >= initialNavbarPosition) {
      removeClassOnScroll(navbar, 'navbar-expand-lg')
      removeClassOnScroll(navbarBrand, 'd-none')
      addAttributeOnScroll(navbarMenu, 'data-bs-toggle', 'collapse')
    } else {
      addClassOnScroll(navbar, 'navbar-expand-lg')
      addClassOnScroll(navbarBrand, 'd-none')
      removeAttributeOnScroll(navbarMenu, 'data-bs-toggle')
    }
  })
})
